export default {
    state: () => ({
        'slug' : null,
        'defaultSlug' : config.locale.defaultLocale
    }),
    mutations: {
        slug(state, payload) {
            state.slug = payload;
        }
    },
    actions: {

    },
    getters: {
        slug (state) {
            if ( ! state.slug || state.slug === null) {
                return state.defaultSlug
            }

            return state.slug;
        }
    }
};
